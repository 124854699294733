import { createContext, useContext } from 'react';
import { PeriodicReviewMode, ReviewStep } from '../components/shared/periodic-review/ReviewWizard';
import { PeriodicReviewConfig } from '../models/Form';

type PeriodicReviewContext = {
  config: PeriodicReviewConfig;
  setConfig?: (value: PeriodicReviewConfig | ((prev: PeriodicReviewConfig) => PeriodicReviewConfig)) => void;
  cancel?: () => void;
  save?: (periodicReviewConfig: PeriodicReviewConfig | null) => void;
  remove?: () => void;
  nextStep?: (stepName: ReviewStep) => void;
  prevStep?: () => void;
  stepNames?: readonly ReviewStep[];
  state?: PeriodicReviewMode;
  wizardTitle?: string;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const PeriodicReviewContext = createContext<PeriodicReviewContext>(null!);
export const usePeriodicReviewWizard = (): PeriodicReviewContext => useContext(PeriodicReviewContext);
